@import '../../styles/contants.scss';

.wrapper {
    background: white;

    .container {
        align-items: flex-start;
        gap: 2rem;
        flex-wrap: wrap;

        >div {
            flex: 1;
        }

        .leftSide {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .exp {
                display: flex;
                align-items: center;
                border: 1px solid $secondary-white;
                border-radius: 20px;
                padding: 1.5rem;
                gap: 2rem;
                transition: all 200ms ease-out;

                &:hover {
                    cursor: pointer;
                    box-shadow: 0px 21px 13px rgba(0, 0, 0, 0.04);
                }

                //icon circles
                >:nth-child(1) {
                    padding: 10px;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 999px;
                }

                //description
                >:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    >:nth-child(1) {
                        font-size: 1.6rem;
                        font-weight: bold;

                    }
                }
            }
        }



        .rightSide {
            @media (max-width: $sm) {
                text-align: center;
            }

            display: flex;
            flex-direction: column;
            gap: 2rem;

            .stats {
                justify-content: flex-start;
                gap: 4rem;

                .stat {
                    flex-direction: column;
                    justify-content: flex-start;
                }
            }
        }
    }
}