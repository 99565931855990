@import './contants.scss';

.bg-primary {
  background: #F8F7F1;
}

.innerWidth {
  @media (min-width: $xxl) {
    max-width: 1280px;
    margin: auto;
  }

  width: 100%;
}

.paddings {
  @media (min-width: $sm) {
    padding: 1rem;
  }


  @media (min-width: $xl),
  (min-width: $md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}



.xPaddings {
  @media (min-width: $sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  padding-left: 1.5rem;
  /* 24px */
  padding-right: 1.5rem;
  /* 24px */
}

.yPaddings {
  @media (min-width: $sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @media (min-width: $xl) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  padding-top: 3rem;
  padding-bottom: 3rem;
}

.topPaddings {
  @media (min-width : $sm) {
    padding-top: 4rem;
  }

  @media (min-width: $xl) {
    padding-top: 2rem;
  }

  padding-top: 3rem;
}


.bottomPaddings {
  @media (min-width : $sm) {
    padding-bottom: 4rem;
  }

  @media (min-width: $xl) {
    padding-bottom: 2rem;
  }

  padding-bottom: 3rem;
}


.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.res {
  display: flex;
  justify-content: center;
  align-items: center;
}

.res {
  @media (max-width: $sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.flexStart {
  display: flex;
  justify-content: start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-items: end;
}

.flexBetween {
  @media (max-width: $xsm) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 5px;
  }

  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.skillUl {
  @media (max-width: $xsm) {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }
  gap: 20px;
}


.head {
  @media (max-width: $xsm) {
    display: flex;
    justify-content: center;
    
  }
  margin-top: 20px;
  margin-bottom: 20px;
}

.ulDesign {
  @media (max-width: $xsm) {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    gap: 1rem;
  }
}

.cssheading{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  line-height: 35px;

}

.skillItem {
  @media (max-width: $xsm) {
    padding: 0px;
    // list-style: none;

  }

  padding-top: 20px;
  padding-bottom: 20px;
}

.primaryText {
  @media (max-width: $sm) {
    font-size: 2.0rem;
  }

  font-size: 2.5rem;
  font-weight: bold;
}

.secondaryText {
  @media (max-width: $sm) {
    font-size: 1.1rem;
    line-height: 40px;
  }

  line-height: 50px;
  font-size: 1.3rem;
  font-weight: 500
}

.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}